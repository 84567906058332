body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

.App_app__sT14H {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: row;
}

.App_appBody__3rxFU {
  width: 100%;
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.App_copyright__2RXHv {
  display: flex;
  padding-left: 20px;
  font-style: italic;
  font-size: 10px;
  align-items: center;
  color: var(--blue);
}

:root {
  --gray: rgb(149, 151, 153);
  --peach: rgb(229, 131, 132);
  --blue: rgb(20, 81, 112);
}

.Header_sideBar__2tXgl {
  width: 300px;
  padding-top: 100px;
  padding-left: 50px;
  position: fixed;
  top: 0;
  left: 0;
}

.Header_sideBarTitle__1GYdc {
  width: 200px;
}

.Header_blueText__3yzNG {
  color: rgb(20, 81, 112);
  color: var(--blue);
}

.Header_pinkText__ktiAo {
  color: rgb(229, 131, 132);
  color: var(--peach);
}

.Header_navigation__1owfO {
  display: flex;
  flex-direction: column;
}

.Header_sideBarLink__3CAWn {
  padding-top: 10px;
}

a.Header_blueText__3yzNG:hover {
  color: rgb(229, 131, 132);
  color: var(--peach);
}

a.Header_pinkText__ktiAo:hover {
  color: rgb(20, 81, 112);
  color: var(--blue);
}

.Header_header__2ekMB.Header_active__20d5T {
  background: rgb(229, 131, 132);
  background: var(--peach);
}

.Header_social__bnKpv {
  padding-top: 50px;
  display: flex;
  flex-direction: row;
  width: 100px;
}

.Header_socialItem__3qgz6 {
  font-size: 20px;
  margin-right: 20px;
  cursor: pointer;
  color: rgb(20, 81, 112);
  color: var(--blue);
}

.Header_socialItem__3qgz6:hover {
  color: rgb(229, 131, 132);
  color: var(--peach);
}

/* Mobile CSS */

.Header_topHeader__1y__d {
  background-color: white;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1;
}

.Header_miniHeader__vFTcN {
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Header_miniHeaderTitle__27EXI {
  flex: 1 1;
  max-height: 100%;
  max-width: 100%;
  height: 0;
  object-fit: contain;
}

.Header_dropdown__S6ar0 {
  width: 100%;
  height: 50px;
}

.Header_dropdownContent__GPiS8 {
  width: 100%;
  z-index: 1;
  background: white;
}

.Header_menuButton__cJ51E {
  text-align: center;
  line-height: 50px;
  cursor: pointer;
}

.Header_menuItem__2Ac_4 {
  width: 100%;
  height: 50px;
  text-align: center;
}

.Header_menuItem__2Ac_4 a {
  line-height: 50px;
}

.Header_link__-tUOu {
  color: rgb(20, 81, 112);
  color: var(--blue);
}

.Header_link__-tUOu:hover {
  color: rgb(229, 131, 132);
  color: var(--peach);
}

.dropdown {
  display: inline-block;
}

.dropdown__content {
  display: none;
  position: absolute;
}

.dropdown--active .dropdown__content {
  display: block;
}

/* Ref: https://loading.io/css/ */

.lds-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.lds-default {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-default-hidden {
  display: none !important;
}

.lds-default div {
  position: absolute;
  width: 5px;
  height: 5px;
  background: var(--peach);
  border-radius: 50%;
  -webkit-animation: lds-default 1.2s linear infinite;
          animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  top: 29px;
  left: 53px;
}
.lds-default div:nth-child(2) {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
  top: 18px;
  left: 50px;
}
.lds-default div:nth-child(3) {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
  top: 9px;
  left: 41px;
}
.lds-default div:nth-child(4) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
  top: 6px;
  left: 29px;
}
.lds-default div:nth-child(5) {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
  top: 9px;
  left: 18px;
}
.lds-default div:nth-child(6) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
  top: 18px;
  left: 9px;
}
.lds-default div:nth-child(7) {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
  top: 29px;
  left: 6px;
}
.lds-default div:nth-child(8) {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
  top: 41px;
  left: 9px;
}
.lds-default div:nth-child(9) {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
  top: 50px;
  left: 18px;
}
.lds-default div:nth-child(10) {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
  top: 53px;
  left: 29px;
}
.lds-default div:nth-child(11) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
  top: 50px;
  left: 41px;
}
.lds-default div:nth-child(12) {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
  top: 41px;
  left: 50px;
}
@-webkit-keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

.Profile_profile__2gAuh {
    height: 100%;
    width: 100%;
    display: flex;
}

.Profile_placeHolder__3q-DC {
}

.Profile_mainContent__1p4Mu {
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

.Profile_gallery__20PgN {
    display: grid;
    grid-template-columns: repeat(6, 1fr);

    /* To by dynamically set*/
    grid-auto-rows: 90px;
}

.Profile_card__2ATwi {
    overflow: hidden;
    align-items: center;
    justify-content: center;
    grid-column-end: span 2;
    grid-row-end: span 2;
}

.Profile_card__2ATwi img {
    display: flex;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.Profile_arrow__-VcdT {
    margin-right: 10px;
}

.Profile_footer__24z9W {
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.Thumbnail_thumbnail__sAJCG {
  /*background: red;*/
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.Thumbnail_thumbnail__sAJCG img {
  max-width: 100%;
  max-height: 100%;
  border: 3px solid;
  box-sizing: border-box;
  border-color: white;
}

.Thumbnail_thumbnailSelected__1paNG {
  border-color: var(--blue) !important;
}

.Carousel_container__kvBkL {
    width: 100%;
    flex: 1 1;
    background: white;
    display: flex;
    flex-direction: row;
}

.Carousel_placeHolder__1fCJ9 {}

.Carousel_mainContent__4RpDp {
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

.Carousel_carousel__3_ixk {
    display: flex;
    flex: 1 1;
    flex-direction: column;
}

.Carousel_cancelContainer__x4xD7 {
    text-align: right;
    font-size: 30px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
    z-index: 1;
}

.Carousel_cancelButton__1Eq6N {
    margin-top: 15px;
    color: var(--blue);
}

.Carousel_cancelButton__1Eq6N:hover {
    color: var(--peach);
}

.Carousel_carouselItem__QUSh8 {
    display: flex !important;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.Carousel_carouselHeader__2Cq90 {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.Carousel_carouselDescription__3X9X- {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Carousel_carouselItemImage__1ri38 {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    width: 100%;
    height: 0;
}

.Carousel_carouselItemImage__1ri38 img {
    flex: 1 1;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    /*width: 100%;*/
}

.Carousel_carouselItemPrimary__BWkqB {
    color: var(--blue);
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
}

.Carousel_carouselItemSecondary__3hcD4 {
    color: var(--blue);
    font-size: 12px;
    margin-bottom: 10px;
}

.Carousel_arrow__vRFZX {
    font-size: 40px;
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--blue);
}

.Carousel_thumbnails__PZzRe {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.Carousel_arrow__vRFZX:hover {
    color: var(--peach);
    cursor: pointer;
}
.carousel,
.carousel .slick-slider,
.carousel .slick-list {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.thumbnail .slick-track,
.carousel .slick-track {
  position: absolute;
  height: 100%;
}

.thumbnail .slick-slide > div,
.carousel .slick-slide > div {
  height: 100%;
}

.carousel .slick-arrow {
  display: none !important;
}

.thumbnail .slick-slider,
.thumbnail .slick-list {
  height: 100%;
}

.thumbnail .slick-slider {
  flex: 1 1;
}

.AboutPage_container__12SpZ {
    max-width: 100%;
    display: flex;
    flex: 1 1;
    padding: 0 20px;
}

.AboutPage_containerVertical__1dcfM {
    flex-direction: column;
}

.AboutPage_pictureContainer__1a44m {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    width: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.AboutPage_pictureContainerHorizontal__2LQU8 {
    margin-right: 20px;
    background-position: top;
}

.AboutPage_aboutDivider__1OjXi {
    flex: 1 1;
}

.AboutPage_info__3Jzoa {
    max-width: 500px;
}

.AboutPage_infoHorizontal__1BJpS {
    width: 50%;
}

.AboutPage_infoTitle__27xqW {
    font-weight: bold;
    font-size: 20px;
    color: #15526d;
}

.AboutPage_infoBody__2yhW9 {
    margin-top: 20px;
    line-height: 24px;
    text-justify: inter-word;
    color: #505050;
}

.AboutPage_resume__1_5X7 {
    margin-top: 20px;
    font-weight: bold;
    text-decoration: underline;
}

.AboutPage_resume__1_5X7 a {
    color: #e58283;
}

.AboutPage_resume__1_5X7 a:hover {
    color: #e58283;
}

