a {
  text-decoration: none;
}

.app {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: row;
}

.appBody {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.copyright {
  display: flex;
  padding-left: 20px;
  font-style: italic;
  font-size: 10px;
  align-items: center;
  color: var(--blue);
}
