:root {
  --gray: rgb(149, 151, 153);
  --peach: rgb(229, 131, 132);
  --blue: rgb(20, 81, 112);
}

.sideBar {
  width: 300px;
  padding-top: 100px;
  padding-left: 50px;
  position: fixed;
  top: 0;
  left: 0;
}

.sideBarTitle {
  width: 200px;
}

.blueText {
  color: var(--blue);
}

.pinkText {
  color: var(--peach);
}

.navigation {
  display: flex;
  flex-direction: column;
}

.sideBarLink {
  padding-top: 10px;
}

a.blueText:hover {
  color: var(--peach);
}

a.pinkText:hover {
  color: var(--blue);
}

.header.active {
  background: var(--peach);
}

.social {
  padding-top: 50px;
  display: flex;
  flex-direction: row;
  width: 100px;
}

.socialItem {
  font-size: 20px;
  margin-right: 20px;
  cursor: pointer;
  color: var(--blue);
}

.socialItem:hover {
  color: var(--peach);
}

/* Mobile CSS */

.topHeader {
  background-color: white;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1;
}

.miniHeader {
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.miniHeaderTitle {
  flex: 1;
  max-height: 100%;
  max-width: 100%;
  height: 0;
  object-fit: contain;
}

.dropdown {
  width: 100%;
  height: 50px;
}

.dropdownContent {
  width: 100%;
  z-index: 1;
  background: white;
}

.menuButton {
  text-align: center;
  line-height: 50px;
  cursor: pointer;
}

.menuItem {
  width: 100%;
  height: 50px;
  text-align: center;
}

.menuItem a {
  line-height: 50px;
}

.link {
  color: var(--blue);
}

.link:hover {
  color: var(--peach);
}
