.container {
    max-width: 100%;
    display: flex;
    flex: 1;
    padding: 0 20px;
}

.containerVertical {
    flex-direction: column;
}

.pictureContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.pictureContainerHorizontal {
    margin-right: 20px;
    background-position: top;
}

.aboutDivider {
    flex: 1;
}

.info {
    max-width: 500px;
}

.infoHorizontal {
    width: 50%;
}

.infoTitle {
    font-weight: bold;
    font-size: 20px;
    color: #15526d;
}

.infoBody {
    margin-top: 20px;
    line-height: 24px;
    text-justify: inter-word;
    color: #505050;
}

.resume {
    margin-top: 20px;
    font-weight: bold;
    text-decoration: underline;
}

.resume a {
    color: #e58283;
}

.resume a:hover {
    color: #e58283;
}
