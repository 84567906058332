.profile {
    height: 100%;
    width: 100%;
    display: flex;
}

.placeHolder {
}

.mainContent {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.gallery {
    display: grid;
    grid-template-columns: repeat(6, 1fr);

    /* To by dynamically set*/
    grid-auto-rows: 90px;
}

.card {
    overflow: hidden;
    align-items: center;
    justify-content: center;
    grid-column-end: span 2;
    grid-row-end: span 2;
}

.card img {
    display: flex;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.arrow {
    margin-right: 10px;
}

.footer {
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
