.thumbnail {
  /*background: red;*/
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.thumbnail img {
  max-width: 100%;
  max-height: 100%;
  border: 3px solid;
  box-sizing: border-box;
  border-color: white;
}

.thumbnailSelected {
  border-color: var(--blue) !important;
}
